<template>
  <div id="dropdown-type">
    <b-form-group #default="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="computedType"
        :aria-describedby="ariaDescribedby"
      >
        <b-form-radio
          value="OW"
          plain
          class="custom-control-warning"
        >
          <div class="text-body font-medium-1 fw-700">
            {{ $t('flight.OW') }}
          </div>
        </b-form-radio>
        <b-form-radio
          v-if="enableRoundTrip"
          plain
          value="RT"
          class="custom-control-warning"
        >
          <div class="text-body font-medium-1 fw-700">
            {{ $t('flight.RT') }}
          </div>
        </b-form-radio>
        <!-- <b-form-radio
          plain
          value="MC"
          class="custom-control-warning"
        >
          <div class="text-dark font-weight-bolder">
            {{ $t('flight.MC') }}
          </div>
        </b-form-radio> -->
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>
<script>
import { BFormGroup, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    type: {
      type: String,
      default: 'OW',
    },
    enableRoundTrip: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const computedType = computed({
      get: () => props.type,
      set: newType => {
        emit('update:type', newType)
      },
    })
    return {
      computedType,
    }
  },
}
</script>

<style lang="scss" scoped>
#dropdown-type {
  .CustomCheckbox::v-deep {
    display: flex;
    align-items: center;

    .custom-control-label {
      margin: 4px 0 !important;

      span {
        font-size: 1.2rem;
      }
    }

    &.label_disabled {
      .custom-control-label {
        span {
          color: #999999 !important;
        }
      }
    }
  }
}
</style>
